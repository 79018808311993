import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pools'
import { BIG_ZERO } from 'utils/bigNumber'
import { DomainsState, Domain, AppThunk } from 'state/types'
import { getPoolApr } from 'utils/apr'
import { getBalanceNumber } from 'utils/formatBalance'
import { getAddress } from 'utils/addressHelpers'
import {
  fetchUserTotalDomains,
  fetchUserDomainIds,
  fetchDomainsByIds,
  fetchPoolsBlockLimits,
  fetchPoolsStakingLimits,
  fetchPoolsTotalStaking,
} from './fetchPools'
import {
  fetchDomainsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
} from './fetchPoolsUser'
import { fetchPublicVaultData, fetchVaultFees } from './fetchVaultPublic'
import fetchVaultUser from './fetchVaultUser'

const initialState: DomainsState = {
  data: [],
  userDataLoaded: false,
}

// Thunks
export const fetchUserDomainsAsync = (account) => async (dispatch, getState) => {
  const userTotalDomains = await fetchUserTotalDomains(account)
  if (userTotalDomains > 0) {
    const domainIds = await fetchUserDomainIds(account, userTotalDomains)

    const domains = await fetchDomainsByIds(domainIds)

    const liveData = domains
      .filter((domain) => domain[0][8] !== '')
      .map((domain) => {
        return {
          tokenId: new BigNumber(domain[0][2].toNumber()).toJSON(),
          ownerAddress: domain[0][0],
          payerAddress: domain[0][1],
          beginTime: new BigNumber(domain[0][3].toNumber()).toJSON(),
          expiryTime: new BigNumber(domain[0][4].toNumber()).toJSON(),
          forSale: domain[0][5],
          salePrice: new BigNumber(domain[0][6].toString()).toJSON(),
          // tokenURI: domain[0][7],
          nftName: domain[0][7],
          nameXTExt: domain[0][8],
          tokenURI: domain[0][9],
        }
      })

    dispatch(setDomains(liveData))
  } else {
    dispatch(setDomains([]))
  }
}

export const fetchDomainsUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchDomainsAllowance(account)

    const userData = poolsConfig.map((pool) => ({
      tokenId: pool.sousId,
      allowance: allowances[pool.sousId],
    }))

    dispatch(setDomainsUserData(userData))
  }

export const updateUserAllowance =
  (sousId: number, account: string): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchDomainsAllowance(account)
    dispatch(updateDomainsUserData({ sousId, field: 'allowance', value: allowances[sousId] }))
  }

export const DomainsSlice = createSlice({
  name: 'Domains',
  initialState,
  reducers: {
    setDomains: (state, action) => {
      const liveDomainsData: Domain[] = action.payload
      state.data = liveDomainsData
    },
    setDomainsPublicData: (state, action) => {
      const liveDomainsData: Domain[] = action.payload
      state.data = state.data.map((domain) => {
        const liveDomainData = liveDomainsData.find((entry) => entry.tokenId === domain.tokenId)
        return { ...domain, ...liveDomainData }
      })
    },
    setDomainsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((domain) => {
        const userDomainData = userData.find((entry) => entry.tokenId === domain.tokenId)
        return { ...domain, userData: userDomainData }
      })
      state.userDataLoaded = true
    },
    updateDomainsUserData: (state, action) => {
      const { field, value, sousId } = action.payload
      const index = state.data.findIndex((p) => p.tokenId === sousId)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
})

// Actions
export const { setDomains, setDomainsPublicData, setDomainsUserData, updateDomainsUserData } = DomainsSlice.actions

export default DomainsSlice.reducer
