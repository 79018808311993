import BigNumber from 'bignumber.js'
import lockersConfig from 'config/constants/lockers'
import sousChefABI from 'config/abi/sousChef.json'
import cakeABI from 'config/abi/cake.json'
import wbnbABI from 'config/abi/weth.json'
import lockerABI from 'config/abi/locker.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress, getWbnbAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getSouschefV2Contract } from 'utils/contractHelpers'

export const fetchLockersDatas = async () => {
  const callsReleaseTime = lockersConfig.map((lockerConfig) => {
    return {
      address: getAddress(lockerConfig.contractAddress),
      name: 'releaseTime',
    }
  })

  const callsBeneficiary = lockersConfig.map((lockerConfig) => {
    return {
      address: getAddress(lockerConfig.contractAddress),
      name: 'beneficiary',
    }
  })

  const starts = await multicall(lockerABI, callsReleaseTime)

  const beneficiarys = await multicall(lockerABI, callsBeneficiary)


  return lockersConfig.map((cakePoolConfig, index) => {
    const startBlock = starts[index]

    return {
      lid: cakePoolConfig.lid,
      releaseTime: new BigNumber(startBlock).toJSON(),
      beneficiary: beneficiarys[index]
    }
  })
}


export const fetchLockersBalanceDatas = async () => {

  const callsBalance = lockersConfig.map((lockerConfig) => {
    return {
      address: getAddress(lockerConfig.token.address),
      name: 'balanceOf',
      // params: [getAddress(lockerConfig.beneficiary)],
      params: [getAddress(lockerConfig.contractAddress)],
    }
  })


  const ends = await multicall(erc20ABI, callsBalance)


  return lockersConfig.map((cakePoolConfig, index) => {

    const endBlock = ends[index]
    return {
      lid: cakePoolConfig.lid,
      balance: new BigNumber(endBlock).toJSON(),

    }
  })
}


export const fetchLockersBlockLimits = async () => {
  const lockersWithEnd = lockersConfig.filter((p) => p.lid !== 0)
  const callsStartBlock = lockersWithEnd.map((lockerConfig) => {
    return {
      address: getAddress(lockerConfig.contractAddress),
      name: 'startBlock',
    }
  })
  const callsEndBlock = lockersWithEnd.map((lockerConfig) => {
    return {
      address: getAddress(lockerConfig.contractAddress),
      name: 'bonusEndBlock',
    }
  })

  const starts = await multicall(sousChefABI, callsStartBlock)
  const ends = await multicall(sousChefABI, callsEndBlock)

  return lockersWithEnd.map((cakePoolConfig, index) => {
    const startBlock = starts[index]
    const endBlock = ends[index]
    return {
      sousId: cakePoolConfig.lid,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}

export const fetchLockersTotalStaking = async () => {
  const nonBnbPools = lockersConfig.filter((p) => p.token.symbol !== 'BNB')
  const bnbPool = lockersConfig.filter((p) => p.token.symbol === 'BNB')

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.token.address),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.lid,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.lid,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}

export const fetchLockerStakingLimit = async (lid: number): Promise<BigNumber> => {
  try {
    const sousContract = getSouschefV2Contract(lid)
    const stakingLimit = await sousContract.poolLimitPerUser()
    return new BigNumber(stakingLimit.toString())
  } catch (error) {
    return BIG_ZERO
  }
}

export const fetchLockersStakingLimits = async (
  lockersWithStakingLimit: number[],
): Promise<{ [key: string]: BigNumber }> => {
  const validPools = lockersConfig
    .filter((p) => p.token.symbol !== 'BNB')
    .filter((p) => !lockersWithStakingLimit.includes(p.lid))

  // Get the staking limit for each valid pool
  // Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
  const stakingLimitPromises = validPools.map((validPool) => fetchLockerStakingLimit(validPool.lid))
  const stakingLimits = await Promise.all(stakingLimitPromises)

  return stakingLimits.reduce((accum, stakingLimit, index) => {
    return {
      ...accum,
      [validPools[index].lid]: stakingLimit,
    }
  }, {})
}
