import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'XTTP Non-fungible Domain Name System',
  description:
    'XTTP, the game-changing features & benefits of a new internet protocol'
    +'XTTP will introduce the next generation of the internet, and as such is a very significant evolution from HTTP. Here is a simple explanation of a few crucial differences between XTTP and HTTP:'
    
    +'- HTTP is characterised by a single point of failure. XTTP is not. '
    +'- HTTP can be taken down by a DDoS (Distributed Denial of Service) attack. XTTP cannot be taken down by a DDoS attack of any size. These attacks cost operators hundreds of millions of dollars every year.'      
    +'- HTTP needs a cloud proxy service to protect itself from DDoS attacks. The cloud proxy service becomes an access/connection bottleneck and it is also a single point of failure. XTTP can work alongside cloud proxy services to create a more resilient architecture.'         
    +'- HTTP needs a centralised DNS (Domain Name System / Service), which is again a single point of failure. XTTP has its own decentralised DNS, which is not a single point of failure.' 
    +'- HTTP needs a CDN (Content Delivery / Distribution Network) to scale. XTTP can scale without a CDN, but it could be stronger when combined with CDN services.'
    +'- XTTP is versatile and goes far beyond the capabilities and applications of HTTP – it can help any network protocol to scale limitlessly.',
  image: 'https://xttp.io/images/avatar.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Find a domain')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/domains':
      return {
        title: `${t('Domains')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/lockers':
      return {
        title: `${t('Lockers')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('XTTP Non-fungible Domain Name System')}`,
      }
    default:
      return null
  }
}
