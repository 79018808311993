import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import throttle from 'lodash/throttle'
import BigNumber from 'bignumber.js'

import useAuth from 'hooks/useAuth'
import { languageList } from 'config/localization/languages'

import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd, usePriceBnbBusd, usePriceXttBusd } from 'state/farms/hooks'
import { useXttBusdPrice } from 'hooks/useBUSDPrice'
import { useProfile } from 'state/profile/hooks'

import { Button, Text, useWalletModal } from '@pancakeswap/uikit'

import Overlay from './components/Overlay/Overlay'
import Flex from './components/Box/Flex'
import { useMatchBreakpoints } from './hooks'
import Logo from './widgets/Menu/components/Logo'
import Panel from './widgets/Menu/components/Panel'
import IconButton from './components/Button/IconButton'
import CakePrice from './widgets/Menu/components/CakePrice'
import BnbPrice from './widgets/Menu/components/BnbPrice'
import { BinanceIcon, CogIcon } from './components/Svg'
import Link from './components/Link/Link'

import ThemeSwitcher from './widgets/Menu/components/ThemeSwitcher'
import LangSelector from './widgets/Menu/components/LangSelector'
import SocialLinks from './widgets/Menu/components/SocialLinks'

import UserMenu from './UserMenu'

// import { NavProps } from "./types";

import { NavProps } from './widgets/Menu/types'

import config, { MENU_HEIGHT, SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL, MENU_ENTRY_HEIGHT } from './config'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Container = styled.div`
  flex: none;
  padding: 8px 4px;
  background-color: ${({ theme }) => theme.nav.background};
  border-top: solid 2px rgba(133, 133, 133, 0.1);
`

const StyledNav = styled.nav<{ showMenu: boolean }>`
  position: fixed;
  top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT}px`)};
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.nav.background};
  border-bottom: solid 2px rgba(133, 133, 133, 0.1);
  z-index: 20;
  transform: translate3d(0, 0, 0);
`

const MenuWrapper = styled.div`
  display: block;

  @media (min-width: 20px) and (max-width: 886px) {
    display: none;
  }
`

const ExtraStyledNav = styled.nav<{ showMenu: boolean }>`
  position: fixed;
  top: ${({ showMenu }) => (showMenu ? `${MENU_HEIGHT}px` : `-${MENU_HEIGHT + 40}px`)};
  left: 0;
  transition: top 0.2s;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.nav.background};
  border-bottom: solid 2px rgba(133, 133, 133, 0.1);
  z-index: 20;
  transform: translate3d(0, 0, 0);

  @media (min-width: 20px) and (max-width: 886px) {
    display: flex;
  }
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: #000000;
  background: url(images/background.png) no-repeat bottom right fixed;

  @media (min-width: 320px) and (max-width: 480px) {
    background-size: 50%;
  }
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  margin-top: ${({ showMenu }) => (showMenu ? `${MENU_HEIGHT}px` : 0)};
  margin-bottom: 20px;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    margin-left: ${({ isPushed }) => `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
    max-width: ${({ isPushed }) => `calc(100% - ${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px)`};
  }
`

const MobileOnlyOverlay = styled(Overlay)`
  position: fixed;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    display: none;
  }
`

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 16px;
`

const CopyRightWrapper = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  position: fixed;
  // padding-top: ${({ showMenu }) => (showMenu ? '80px' : 0)};
  width: 100%;
  background-color: transparent;
  max-width: 1200px;
  bottom: 0;
  padding: 10px 30px 10px 10px;
  margin-right: auto;
  margin-left: auto;
  // padding-left: ${({ showMenu }) => (showMenu ? `${SIDEBAR_WIDTH_FULL}px` : 0)};
  text-align: center;
  // right: 0;
  // margin-top: ${({ showMenu }) => (showMenu ? `${MENU_HEIGHT}px` : 0)};
  // transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  // transform: translate3d(0, 0, 0);
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    // margin-right: ${({ isPushed }) => `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
    // width: ${({ isPushed }) => `calc(100% - ${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px)`};
  }

  @media (min-width: 320px) and (max-width: 768px) {
    padding-left: 0;
  }
`

const SocialLinksWrapper = styled.div`
  padding: 0 10px 0px 0px;
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`

const NoteContainer = styled.div`
  flex: none;
  //max-width: 300px;
  padding: 0px 0px;
  //	background-color: ${({ theme }) => theme.nav.background};
  //	border-top: solid 2px rgba(133, 133, 133, 0.1);
`
/*
const Menu: React.FC<NavProps> = ({
  userMenu,
  globalMenu,
  isDark,
  toggleTheme,
  langs,
  setLang,
  currentLang,
  cakePriceUsd,
  links,
  children,
}) => {
*/
const Menu: React.FC = ({ children }) => {
  const { login, logout } = useAuth()
  const { isDark, toggleTheme } = useTheme()

  const location = useLocation()

  const cakePriceUsd = usePriceCakeBusd()
  const bnbPriceUsd = usePriceBnbBusd()
  const xttPriceUsd = usePriceXttBusd()

  const { profile } = useProfile()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const links = config(t)

  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const [isPushed, setIsPushed] = useState(!isMobile)
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(window.pageYOffset)

  const { onPresentConnectModal } = useWalletModal(login, logout)

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  return (
    <Wrapper>
      <ExtraStyledNav showMenu={showMenu}>
        <Flex style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Link
            style={{
              marginRight: '0px',
              fontWeight: 'normal',
              color: location.pathname === '/' ? '#42a9c2' : '#a1a1a1',
              textAlign: 'center',
            }}
            href="/"
          >
            {t('Find Domains')}
          </Link>
          <Link
            style={{
              marginRight: '0px',
              fontWeight: 'normal',
              color: location.pathname === '/domains' ? '#42a9c2' : '#a1a1a1',
              textAlign: 'center',
            }}
            href="/domains"
          >
            {t('My Domains')}
          </Link>
          {/*
          <Link
            style={{ fontWeight: 'normal', color: '#a1a1a1', textAlign: 'center' }}
            href="https://oxtobot.com"
            target="_blank"
          >
            {t('Download oXTobot')}
          </Link>
          */}
        </Flex>
      </ExtraStyledNav>
      <StyledNav showMenu={showMenu}>
        <Logo
          isPushed={isPushed}
          togglePush={() => setIsPushed((prevState: boolean) => !prevState)}
          isDark={isDark}
          href={homeLink?.href ?? '/'}
        />
        <Flex style={{ alignItems: 'center' }}>
          <MenuWrapper>
            <Flex style={{ marginRight: '30px' }}>
              <Link
                style={{
                  marginRight: '40px',
                  fontWeight: 'normal',
                  color: location.pathname === '/' ? '#42a9c2' : '#a1a1a1',
                }}
                href="/"
              >
                {t('Find Domains')}
              </Link>
              <Link
                style={{
                  marginRight: '10px',
                  fontWeight: 'normal',
                  color: location.pathname === '/domains' ? '#42a9c2' : '#a1a1a1',
                }}
                href="/domains"
              >
                {t('My Domains')}
              </Link>
              {/*
              <Link style={{ fontWeight: 'normal', color: '#a1a1a1' }} href="https://oxtobot.com" target="_blank">
                {t('Download oXTobot')}
              </Link>
              */}
            </Flex>
          </MenuWrapper>
          <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
          <SocialLinksWrapper>
            <SocialLinks />
          </SocialLinksWrapper>
          <Button
            as="button"
            scale={isMobile ? 'xs' : 'sm'}
            style={{
              backgroundColor: '#42a9c2',
              backgroundImage: 'linear-gradient(to right, #42a9c2, #42a9c2)',
              marginRight: '10px',
            }}
            startIcon={<BinanceIcon />}
          >
            BSC
          </Button>
          <UserMenu />
        </Flex>
      </StyledNav>

      <BodyWrapper>
        {/*
				<Panel
					isPushed={isPushed}
					isMobile={isMobile}
					showMenu={showMenu}
					isDark={isDark}
					toggleTheme={toggleTheme}
					langs={languageList}
					setLang={setLanguage}
					currentLang={currentLanguage.code}
					// cakePriceUsd={cakePriceUsd.toNumber()}
					cakePriceUsd={xttPriceUsd ? (new BigNumber(xttPriceUsd.toFixed(18))).toNumber() : 0}
					pushNav={setIsPushed}
					links={links}
				/>
				*/}
        <Inner isPushed={isPushed} showMenu={showMenu}>
          {children}
        </Inner>
        <CopyRightWrapper isPushed={isPushed} showMenu={showMenu}>
          <Text>
            <a target="_blank" rel="noopener noreferrer" href="https://xtblock.io">
              {t('Developed by XTblock ©2022')}
            </a>
          </Text>
          {/*
          <NoteContainer>
            <Text fontSize="10px">
              {t(
                'The language translations on this website are automated, and may not be perfectly accurate. Please keep this in mind while using this website.',
              )}
            </Text>
          </NoteContainer>
              */}
        </CopyRightWrapper>
        <MobileOnlyOverlay show={isPushed} onClick={() => setIsPushed(false)} role="presentation" />
      </BodyWrapper>
    </Wrapper>
  )
}

export default Menu
