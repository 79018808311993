import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="48.000000pt" height="45.000000pt" viewBox="0 0 48.000000 45.000000"
      preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(0.000000,45.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M100 367 c-47 -39 -85 -73 -85 -77 0 -4 39 -39 88 -79 l87 -71 0 35
0 35 60 0 60 0 0 30 0 31 65 -53 c36 -28 65 -54 65 -58 0 -4 -29 -30 -65 -58
-62 -51 -65 -52 -65 -27 l0 25 -130 0 c-80 0 -130 -4 -130 -10 0 -6 47 -10
120 -10 l120 0 0 -35 0 -35 88 71 c48 40 87 75 87 79 0 4 -39 39 -87 79 l-88
71 0 -40 0 -40 -60 0 c-58 0 -60 -1 -60 -25 0 -25 -3 -24 -65 27 -36 28 -65
54 -65 58 0 4 29 30 65 58 62 51 65 52 65 27 l0 -25 130 0 c80 0 130 4 130 10
0 6 -46 10 -119 10 l-120 0 -3 33 -3 33 -85 -69z"/>
      </g>
    </Svg>
  );
};

export default Icon;
