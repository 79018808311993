import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import lockersConfig from 'config/constants/lockers'
import { BIG_ZERO } from 'utils/bigNumber'
import { LockersState, Locker, CakeVault, VaultFees, VaultUser, AppThunk } from 'state/types'
import { getPoolApr } from 'utils/apr'
import { getBalanceNumber } from 'utils/formatBalance'
import { getAddress } from 'utils/addressHelpers'
import {fetchLockersDatas, fetchLockersBalanceDatas, fetchLockersBlockLimits, fetchLockersStakingLimits, fetchLockersTotalStaking } from './fetchLockers'
import {
  fetchLockersAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
} from './fetchLockersUser'
import { fetchPublicVaultData, fetchVaultFees } from './fetchVaultPublic'
import fetchVaultUser from './fetchVaultUser'
import { getTokenPricesFromFarm } from './helpers'

const initialState: LockersState = {
  data: [...lockersConfig],
  userDataLoaded: false,
  cakeVault: {
    totalShares: null,
    pricePerFullShare: null,
    totalCakeInVault: null,
    estimatedCakeBountyReward: null,
    totalPendingCakeHarvest: null,
    fees: {
      performanceFee: null,
      callFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    },
    userData: {
      isLoading: true,
      userShares: null,
      cakeAtLastUserAction: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
    },
  },
}

// Thunks
export const fetchLockersPublicDataAsync = (currentBlock: number) => async (dispatch, getState) => {
  const blockLimits = await fetchLockersBlockLimits()
  const totalStakings = await fetchLockersTotalStaking()

  const prices = getTokenPricesFromFarm(getState().farms.data)

  const liveData = lockersConfig.map((locker) => {
    const blockLimit = blockLimits.find((entry) => entry.sousId === locker.lid)
    const totalStaking = totalStakings.find((entry) => entry.sousId === locker.lid)
    const isPoolEndBlockExceeded = currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
    const isPoolFinished = isPoolEndBlockExceeded

    const stakingTokenAddress = locker.token.address ? getAddress(locker.token.address).toLowerCase() : null
    const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

    const earningTokenAddress = locker.token.address ? getAddress(locker.token.address).toLowerCase() : null
    const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
    const apr = !isPoolFinished
      ? getPoolApr(
          stakingTokenPrice,
          earningTokenPrice,
          getBalanceNumber(new BigNumber(totalStaking.totalStaked), locker.token.decimals),
          parseFloat("0"),
        )
      : 0

    return {
      ...blockLimit,
      ...totalStaking,
      stakingTokenPrice,
      earningTokenPrice,
      apr,
      isFinished: isPoolFinished,
    }
  })

  dispatch(setLockersPublicData(liveData))
}


// Thunks
export const fetchLockersPublicDataAsync1 = () => async (dispatch, getState) => {
  // const blockLimits = await fetchLockersBlockLimits()
  const blockLimits = await fetchLockersDatas()
  // const totalStakings = await fetchLockersTotalStaking()

  // const prices = getTokenPricesFromFarm(getState().farms.data)

  const liveData = lockersConfig.map((locker) => {
    const blockLimit = blockLimits.find((entry) => entry.lid === locker.lid)
    // const totalStaking = totalStakings.find((entry) => entry.sousId === locker.lid)
    // const isPoolEndBlockExceeded = currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
    // const isPoolFinished = isPoolEndBlockExceeded

    const stakingTokenAddress = locker.token.address ? getAddress(locker.token.address).toLowerCase() : null
    // const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

    const earningTokenAddress = locker.token.address ? getAddress(locker.token.address).toLowerCase() : null
    // const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
    /*
    const apr = !isPoolFinished
      ? getPoolApr(
          stakingTokenPrice,
          earningTokenPrice,
          getBalanceNumber(new BigNumber(totalStaking.totalStaked), locker.token.decimals),
          parseFloat("0"),
        )
      : 0
*/
    return {
      ...blockLimit,
      // ...totalStaking,
      // stakingTokenPrice,
      // earningTokenPrice,
      // apr,
      // isFinished: isPoolFinished,
    }
  })

  dispatch(setLockersPublicData(liveData))
}


// Thunks
export const fetchLockersPublicDataAsync2 = () => async (dispatch, getState) => {
  // const blockLimits = await fetchLockersBlockLimits()
  const blockLimits = await fetchLockersBalanceDatas()
  // const totalStakings = await fetchLockersTotalStaking()

  // const prices = getTokenPricesFromFarm(getState().farms.data)

  const liveData = lockersConfig.map((locker) => {
    const blockLimit = blockLimits.find((entry) => entry.lid === locker.lid)
    // const totalStaking = totalStakings.find((entry) => entry.sousId === locker.lid)
    // const isPoolEndBlockExceeded = currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
    // const isPoolFinished = isPoolEndBlockExceeded

    const stakingTokenAddress = locker.token.address ? getAddress(locker.token.address).toLowerCase() : null
    // const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

    const earningTokenAddress = locker.token.address ? getAddress(locker.token.address).toLowerCase() : null
    // const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
    /*
    const apr = !isPoolFinished
      ? getPoolApr(
          stakingTokenPrice,
          earningTokenPrice,
          getBalanceNumber(new BigNumber(totalStaking.totalStaked), locker.token.decimals),
          parseFloat("0"),
        )
      : 0
*/
    return {
      ...blockLimit,
      // ...totalStaking,
      // stakingTokenPrice,
      // earningTokenPrice,
      // apr,
      // isFinished: isPoolFinished,
    }
  })

  dispatch(setLockersPublicData(liveData))
}


export const fetchLockersStakingLimitsAsync = () => async (dispatch, getState) => {
  const poolsWithStakingLimit = getState()
    .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
    .map((pool) => pool.sousId)

  const stakingLimits = await fetchLockersStakingLimits(poolsWithStakingLimit)

  const stakingLimitData = lockersConfig.map((locker) => {
    if (poolsWithStakingLimit.includes(locker.lid)) {
      return { sousId: locker.lid }
    }
    const stakingLimit = stakingLimits[locker.lid] || BIG_ZERO
    return {
      sousId: locker.lid,
      stakingLimit: stakingLimit.toJSON(),
    }
  })

  dispatch(setLockersPublicData(stakingLimitData))
}

export const fetchLockersUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchLockersAllowance(account)
    const stakingTokenBalances = await fetchUserBalances(account)
    const stakedBalances = await fetchUserStakeBalances(account)
    const pendingRewards = await fetchUserPendingRewards(account)

    const userData = lockersConfig.map((locker) => ({
      sousId: locker.lid,
      allowance: allowances[locker.lid],
      stakingTokenBalance: stakingTokenBalances[locker.lid],
      stakedBalance: stakedBalances[locker.lid],
      pendingReward: pendingRewards[locker.lid],
    }))

    dispatch(setLockersUserData(userData))
  }

export const updateUserAllowance =
  (sousId: number, account: string): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchLockersAllowance(account)
    dispatch(updateLockersUserData({ sousId, field: 'allowance', value: allowances[sousId] }))
  }

export const updateUserBalance =
  (sousId: number, account: string): AppThunk =>
  async (dispatch) => {
    const tokenBalances = await fetchUserBalances(account)
    dispatch(updateLockersUserData({ sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }))
  }

export const updateUserStakedBalance =
  (sousId: number, account: string): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account)
    dispatch(updateLockersUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId] }))
  }

export const updateUserPendingReward =
  (sousId: number, account: string): AppThunk =>
  async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(account)
    dispatch(updateLockersUserData({ sousId, field: 'pendingReward', value: pendingRewards[sousId] }))
  }

export const fetchCakeVaultPublicData = createAsyncThunk<CakeVault>('cakeVault/fetchPublicData', async () => {
  const publicVaultInfo = await fetchPublicVaultData()
  return publicVaultInfo
})

export const fetchCakeVaultFees = createAsyncThunk<VaultFees>('cakeVault/fetchFees', async () => {
  const vaultFees = await fetchVaultFees()
  return vaultFees
})

export const fetchCakeVaultUserData = createAsyncThunk<VaultUser, { account: string }>(
  'cakeVault/fetchUser',
  async ({ account }) => {
    const userData = await fetchVaultUser(account)
    return userData
  },
)

export const LockersSlice = createSlice({
  name: 'Lockers',
  initialState,
  reducers: {
    setLockersPublicData: (state, action) => {
      const liveLockersData: Locker[] = action.payload
      state.data = state.data.map((locker) => {
        const liveLockerData = liveLockersData.find((entry) => entry.lid === locker.lid)
        return { ...locker, ...liveLockerData }
      })
    },
    setLockersUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((locker) => {
        const userLockerData = userData.find((entry) => entry.sousId === locker.lid)
        return { ...locker, userData: userLockerData }
      })
      state.userDataLoaded = true
    },
    updateLockersUserData: (state, action) => {
      const { field, value, lid } = action.payload
      const index = state.data.findIndex((l) => l.lid === lid)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
  extraReducers: (builder) => {
    // Vault public data that updates frequently
    builder.addCase(fetchCakeVaultPublicData.fulfilled, (state, action: PayloadAction<CakeVault>) => {
      state.cakeVault = { ...state.cakeVault, ...action.payload }
    })
    // Vault fees
    builder.addCase(fetchCakeVaultFees.fulfilled, (state, action: PayloadAction<VaultFees>) => {
      const fees = action.payload
      state.cakeVault = { ...state.cakeVault, fees }
    })
    // Vault user data
    builder.addCase(fetchCakeVaultUserData.fulfilled, (state, action: PayloadAction<VaultUser>) => {
      const userData = action.payload
      // userData.isLoading = false
      state.cakeVault = { ...state.cakeVault, userData }
    })
  },
})

// Actions
export const { setLockersPublicData, setLockersUserData, updateLockersUserData } = LockersSlice.actions

export default LockersSlice.reducer
