import tokens from './tokens'
import { LockerConfig, PoolCategory } from './types'

const lockers: LockerConfig[] = [

  {
    lid: 1,
    token: tokens.xttbusd,
    stakingToken: tokens.busd,
    earningToken: tokens.xtt,
    contractAddress: {
      97: '0x819A751f93dF195e1891E1d0E1737107aE8EDbeb',
      56: '0xcc8805898381ec2342d19d7221b2414e123afae1',
    },
    sortOrder: 1,
  },
  {
    lid: 2,
    token: tokens.xtt,
    contractAddress: {
      97: '0x79Bf884A12ceF076522Ef60cd05b68576Ab1239E',
      56: '0x0377204bac669551ff6c42d3132665a13c3cb62b',
    },
    sortOrder: 2,
    description: "Roadmap"
  },
  {
    lid: 3,
    token: tokens.xtt,
    contractAddress: {
      97: '0x79Bf884A12ceF076522Ef60cd05b68576Ab1239E',
      56: '0x3783da5a74e2d524755d4e8f24f9d3d32f7bab61',
    },
    sortOrder: 2,
    description: "Network Incentive"
  },
]

export default lockers
