import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ethers, Contract } from 'ethers'
import { splitSignature } from '@ethersproject/bytes'
import { Modal, Text, Flex, Image, Button, BalanceInput, Input, AutoRenewIcon, Link } from '@pancakeswap/uikit'
import Dots from 'components/Loader/Dots'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import BigNumber from 'bignumber.js'
import { getFullDisplayBalance, formatNumber, getDecimalAmount, getBalanceNumber } from 'utils/formatBalance'
import { Pool } from 'state/types'
import { BASE_URL } from 'config'
import { getAddress, getXTDomainNFTAddress, getXttAddress } from 'utils/addressHelpers'
import { useMatchBreakpoints } from 'components/Menu/hooks'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useXTDomainNFTContract, useXttContract } from 'hooks/useContract'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { getXTDomainNFTContract, getXttContract } from 'utils/contractHelpers'

import Select, { OptionProps } from 'components/Select/Select'

import { useApproveBuy } from '../../../../Home/hooks/useApprove'
import useRenewDomain from '../../../hooks/useRenewDomain'

interface StakeModalProps {
  tokenId: number
  nftName: string
  nameXTExt: string
  tokenURI: string
  expiryTime: BigNumber
  pricePerYear: BigNumber
  onDismiss?: () => void
}

const StyledLink = styled(Link)`
  width: 100%;
`

const RenewModal: React.FC<StakeModalProps> = ({
  tokenId,
  nftName,
  nameXTExt,
  tokenURI,
  pricePerYear,
  expiryTime,
  onDismiss,
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { onRenew } = useRenewDomain()
  const { toastSuccess, toastError } = useToast()
  const [pendingTx, setPendingTx] = useState(false)

  // modal and loading
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  const [stakeAmount, setStakeAmount] = useState('')
  const [hasReachedStakeLimit, setHasReachedStakedLimit] = useState(false)
  const [percent, setPercent] = useState(0)

  const [numOfYear, setNumOfYear] = useState(1)

  const { account, chainId, library } = useActiveWeb3React()
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const numberOfYearOptions = [
    {
      label: t('%year% Year', { year: '1' }),
      value: 1,
    },
    {
      label: t('%year% Years', { year: '2' }),
      value: 2,
    },
    {
      label: t('%year% Years', { year: '3' }),
      value: 3,
    },
    {
      label: t('%year% Years', { year: '4' }),
      value: 4,
    },
    {
      label: t('%year% Years', { year: '5' }),
      value: 5,
    },
    {
      label: t('%year% Years', { year: '6' }),
      value: 6,
    },
    {
      label: t('%year% Years', { year: '7' }),
      value: 7,
    },
    {
      label: t('%year% Years', { year: '8' }),
      value: 8,
    },
    {
      label: t('%year% Years', { year: '9' }),
      value: 9,
    },
    {
      label: t('%year% Years', { year: '10' }),
      value: 10,
    },
  ]

  // xtDomainNFT contract
  const xtDomainNFTContract = useXTDomainNFTContract()

  // allowance handling
  const {
    handleApprove: handleRenewApprove,
    requestedApproval: requestedPoolApproval,
    requestingApproval,
  } = useApproveBuy(useXTDomainNFTContract(), useXttContract(), 'XTT-b20')

  // const handleApprove = handlePoolApprove
  const handleApprove = async () => {
    handleRenewApprove(pricePerYear.multipliedBy(numOfYear))
  }
  const requestedApproval = requestedPoolApproval

  const handleNumberOfYearChange = (option: OptionProps): void => {
    setNumOfYear(option.value)
  }

  /*
  const handleStakeInputChange = (input: string) => {
    if (input) {
      const convertedInput = getDecimalAmount(new BigNumber(input), stakingToken.decimals)
      const percentage = Math.floor(convertedInput.dividedBy(getCalculatedStakingLimit()).multipliedBy(100).toNumber())
      setPercent(Math.min(percentage, 100))
    } else {
      setPercent(0)
    }
    setStakeAmount(input)
  }

  const handleChangePercent = (sliderPercent: number) => {
    if (sliderPercent > 0) {
      const percentageOfStakingMax = getCalculatedStakingLimit().dividedBy(100).multipliedBy(sliderPercent)
      const amountToStake = getFullDisplayBalance(percentageOfStakingMax, stakingToken.decimals, stakingToken.decimals)
      setStakeAmount(amountToStake)
    } else {
      setStakeAmount('')
    }
    setPercent(sliderPercent)
  }
  */
  const handleConfirmClick = async () => {
    setPendingTx(true)

    try {
      // staking
      await onRenew(nftName + nameXTExt, numOfYear)
      // newNFT: string, nameXTExtId: number, tokenURI: string, numOfYear: number
      toastSuccess(
        `${
          Date.now() < getBalanceNumber(expiryTime, 0) * 1000 ? t('Extended Successfully') : t('Renew Successfully')
        }!`,
        t('You have extended %domain% successfully!', {
          domain: nftName + nameXTExt,
        }),
      )
      setPendingTx(false)
      onDismiss()
    } catch (e) {
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      setPendingTx(false)
    }
  }

  return (
    <Modal
      title={Date.now() < getBalanceNumber(expiryTime, 0) * 1000 ? t('Extend Domain') : t('Renew Domain')}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
      minWidth={isMobile ? '90%' : '500px'}
    >
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text>{t('Domain')}:</Text>
        <Text bold>
          {nftName}
          {nameXTExt}
        </Text>
      </Flex>
      {/*
      <BalanceInput
        value={stakeAmount}
        onUserInput={handleStakeInputChange}
        currencyValue={stakingTokenPrice !== 0 && `~${usdValueStaked || 0} USD`}
        isWarning={hasReachedStakeLimit}
        decimals={stakingToken.decimals}
      />
      */}
      <Flex alignItems="center" justifyContent="space-between" mt="8px" mb="8px">
        <Text>{t('Subscribe for:')}</Text>
        <Select options={numberOfYearOptions} onChange={handleNumberOfYearChange} />
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mt="8px" mb="8px">
        <Text bold>{t('Fee:')}</Text>
        <Text bold>{getBalanceNumber(pricePerYear.multipliedBy(numOfYear), 18)} XTT-b20</Text>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Button
          width="100%"
          mt="24px"
          mr="10px"
          disabled={requestedApproval || requestingApproval}
          endIcon={requestingApproval ? <AutoRenewIcon spin color="currentColor" /> : null}
          onClick={handleApprove}
          variant="secondary"
        >
          {t('Enable')}
        </Button>
        <Button
          isLoading={pendingTx}
          endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
          onClick={handleConfirmClick}
          disabled={!requestedApproval}
          width="100%"
          mt="24px"
          ml="10px"
        >
          {pendingTx ? t('Confirming') : t('Confirm')}
        </Button>
      </Flex>
    </Modal>
  )
}

export default RenewModal
