import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Flex, Link, Skeleton, Text, TimerIcon } from '@pancakeswap/uikit'
import { getBscScanBlockCountdownUrl } from 'utils/bscscan'
import { Domain } from 'state/types'
import BigNumber from 'bignumber.js'
// import { useBlock } from 'state/hooks'
import { useBlock } from 'state/block/hooks'
import Balance from 'components/Balance'
import { useTranslation } from 'contexts/Localization'
import { getPoolBlockInfo } from 'views/Pools/helpers'
import { getBalanceNumber } from 'utils/formatBalance'
import BaseCell, { CellContent } from './BaseCell'

interface FinishCellProps {
  pool: Domain
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const EndsInCell: React.FC<FinishCellProps> = ({ pool }) => {
  const { expiryTime } = pool
  const { t } = useTranslation()


  const releaseDate = useMemo(() => {
    if (expiryTime != null) {
     return new Date(getBalanceNumber(expiryTime, 0)* 1000).toLocaleDateString("en-US")
    }

    return ""

  }, [expiryTime])

  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left" mb="5px">
          {t('Expires On')}
        </Text>
        <Flex height="20px" alignItems="center">
          <Text fontSize="16px" color="#212121" bold textAlign="left">
            {releaseDate}
          </Text>
        </Flex>

      </CellContent>
    </StyledCell>
  )
}

export default EndsInCell
