import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers, Contract } from 'ethers'
import BigNumber from 'bignumber.js'
import { useAppDispatch } from 'state'
import { updateUserAllowance } from 'state/actions'
import { useTranslation } from 'contexts/Localization'
import { useCake, useSousChef, useCakeVaultContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import useLastUpdated from 'hooks/useLastUpdated'

export const useApproveBuy = (lpContract: Contract, tokenContract: Contract, earningTokenSymbol) => {
  const [requestedApproval, setRequestedApproval] = useState(false)
  const [requestingApproval, setRequestingApproval] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const handleApprove = useCallback(
    async (amount: BigNumber) => {
      try {
        // setRequestedApproval(true)
        setRequestingApproval(true)
        // const tx = await tokenContract.approve(lpContract.address, ethers.constants.MaxUint256)
        const tx = await tokenContract.approve(lpContract.address, amount.toJSON())
        const receipt = await tx.wait()

        // dispatch(updateUserAllowance(sousId, account))
        if (receipt.status) {
          toastSuccess(
            t('Contract Enabled'),
            t('You can do transaction with %symbol% now!', { symbol: earningTokenSymbol }),
          )
          setRequestedApproval(true)
        } else {
          // user rejected tx or didn't go thru
          toastError(
            t('Error'),
            t('Please try again. Confirm the transaction and make sure you are paying enough gas!'),
          )
          setRequestedApproval(false)
        }
        setRequestingApproval(false)
      } catch (e) {
        console.error(e)
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      }
      // }, [account, dispatch, lpContract, tokenContract, earningTokenSymbol, t, toastError, toastSuccess])
    },
    [lpContract, tokenContract, earningTokenSymbol, t, toastError, toastSuccess],
  )

  return { handleApprove, requestedApproval, requestingApproval }
}
