import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { simpleRpcProvider } from 'utils/providers'
import useRefresh from 'hooks/useRefresh'
import {
  fetchUserDomainsAsync,
} from '.'
import { State, Pool, Domain } from '../types'
import { transformDomain } from './helpers'

export const useFetchPublicDomainsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await simpleRpcProvider.getBlockNumber()
      // dispatch(fetchPoolsPublicDataAsync(blockNumber))
    }

    fetchPoolsPublicData()
    
  }, [dispatch, slowRefresh])
}

export const useDomains = (account): { domains: Domain[]; userDataLoaded: boolean } => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      // dispatch(fetchDomainsUserDataAsync(account))
      dispatch(fetchUserDomainsAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const { domains, userDataLoaded } = useSelector((state: State) => ({
    domains: state.domains.data,
    userDataLoaded: state.domains.userDataLoaded,
  }))
  
  return { domains: domains.map(transformDomain), userDataLoaded }
}