import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { updateUserStakedBalance, updateUserBalance } from 'state/actions'
import { stakeFarm } from 'utils/calls'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL, DEFAULT_GAS_LIMIT } from 'config'
import { BIG_TEN } from 'utils/bigNumber'
import { useMasterchef, useSousChef, useXTDomainNFTContract } from 'hooks/useContract'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const renewDomain = async (xtDomainNFTContract, newNFT, numOfYear) => {
  const tx = await xtDomainNFTContract.extendNFTSubscription(newNFT, numOfYear)
  
  const receipt = await tx.wait()
  return receipt.status
}

const useRenewDomain = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  
  const xtDomainNFTContract = useXTDomainNFTContract()

  const handleRenew = useCallback(
    async (newNFT: string, numOfYear: number) => {
      
      await renewDomain(xtDomainNFTContract, newNFT, numOfYear)
      // dispatch(updateUserStakedBalance(sousId, account))
      // dispatch(updateUserBalance(sousId, account))
    },
    // [account, dispatch, xtDomainNFTContract],
    [xtDomainNFTContract],
  )

  return { onRenew: handleRenew }
}

export default useRenewDomain
