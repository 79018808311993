import BigNumber from 'bignumber.js'
import { Domain } from 'state/types'
import { getAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'

type UserData =
  | Domain['userData']
  | {
      allowance: number | string
    }

export const transformUserData = (userData: UserData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
  }
}

export const transformDomain = (domain: Domain): Domain => {
  const { userData, ...rest } = domain

  return {
    ...rest,
    userData: transformUserData(userData),
  } as Domain
}

