import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="71.000000pt" height="38.000000pt" viewBox="0 0 71.000000 38.000000"
      preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(0.000000,38.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M47 363 c-10 -9 -8 -23 2 -23 5 0 32 -14 60 -31 42 -26 57 -31 91
-26 23 3 58 17 79 31 51 35 98 34 158 -4 63 -40 109 -40 179 3 50 30 61 46 38
54 -6 2 -30 -10 -55 -27 -57 -38 -95 -38 -156 0 -65 41 -121 41 -183 -1 -54
-37 -95 -36 -154 5 -41 28 -49 30 -59 19z"/>
        <path d="M12 218 c3 -7 16 -15 31 -19 14 -4 40 -17 58 -30 18 -12 52 -24 78
-26 39 -4 53 0 91 26 60 41 111 41 164 1 53 -40 115 -41 170 -4 21 15 50 29
64 33 37 10 40 31 5 31 -15 0 -48 -14 -73 -30 -59 -39 -101 -39 -159 0 -59 40
-109 40 -176 0 -63 -37 -98 -37 -154 0 -44 30 -106 41 -99 18z"/>
        <path d="M44 89 c-3 -6 -4 -11 -3 -13 109 -75 155 -83 234 -36 63 38 98 38
155 0 58 -38 121 -41 171 -7 19 13 42 26 52 30 9 3 17 11 17 16 0 22 -20 19
-66 -10 -63 -41 -99 -41 -159 -1 -64 43 -115 43 -176 2 -54 -37 -101 -39 -149
-7 -46 31 -68 38 -76 26z"/>
      </g>
    </Svg>
  );
};

export default Icon;
