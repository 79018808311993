import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="50.000000pt" height="47.000000pt" viewBox="0 0 50.000000 47.000000"
    preserveAspectRatio="xMidYMid meet" {...props}>
     <g transform="translate(0.000000,47.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M126 370 c-64 -50 -116 -96 -116 -103 1 -17 25 -47 38 -47 7 0 12
-37 14 -108 l3 -107 40 0 40 0 3 83 3 82 34 0 35 0 0 -74 c0 -51 4 -77 14 -85
9 -8 47 -11 107 -9 l94 3 3 97 c2 88 5 100 27 124 32 35 31 50 -5 80 -26 22
-29 32 -32 87 l-3 62 -40 0 c-36 0 -40 -3 -43 -28 l-3 -28 -39 31 c-21 16 -42
30 -48 30 -5 0 -62 -41 -126 -90z m232 25 c3 30 7 35 28 35 22 0 24 -3 24 -53
0 -46 4 -57 30 -82 23 -22 27 -32 19 -42 -6 -7 -18 -13 -25 -13 -11 0 -14 -22
-14 -105 l0 -105 -85 0 -85 0 0 80 0 80 -60 0 -60 0 0 -80 c0 -73 -2 -80 -20
-80 -18 0 -20 8 -22 108 -3 93 -5 107 -20 107 -10 0 -22 5 -28 11 -9 9 18 34
100 97 l112 86 52 -39 51 -39 3 34z"/>
<path d="M280 130 l0 -61 58 3 57 3 3 58 3 57 -61 0 -60 0 0 -60z m100 0 l0
-40 -40 0 -40 0 0 40 0 40 40 0 40 0 0 -40z"/>
</g>
    </Svg>
  );
};

export default Icon;
