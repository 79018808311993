import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Flex, Image, Text } from '@pancakeswap/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { useFetchPublicDomainsData, useDomains } from 'state/domains/hooks'
import { usePollFarmsData } from 'state/farms/hooks'
import { latinise } from 'utils/latinise'
import ConnectWalletButton from 'components/ConnectWalletButton'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import { Pool } from 'state/types'
import Loading from 'components/Loading'
import SocialLinks from 'components/Menu/widgets/Menu/components/SocialLinks'
import PoolCard from './components/PoolCard'
import CakeVaultCard from './components/CakeVaultCard'
import PoolTabButtons from './components/PoolTabButtons'
// import BountyCard from './components/BountyCard'
// import HelpButton from './components/HelpButton'
import PoolsTable from './components/PoolsTable/PoolsTable'
import { ViewMode } from './components/ToggleView/ToggleView'
import { getAprData, getCakeVaultEarnings } from './helpers'

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const PoolControls = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 32px;
    margin-bottom: 0;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
  }
  > ${Text} {
    font-size: 16px;
  }
`

const ControlStretch = styled(Flex)`
  > div {
    flex: 1;
  }
`

const ComingSoonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`

const SocialLinksWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px 0;
`

const NUMBER_OF_POOLS_VISIBLE = 12

const Domains: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { domains: poolsWithoutAutoVault, userDataLoaded } = useDomains(account)
  const [stakedOnly, setStakedOnly] = usePersistState(false, { localStorageKey: 'pancake_pool_staked' })
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, { localStorageKey: 'pancake_pool_view' })
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)

  const pools = useMemo(() => {
    /*
    const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
     const cakeAutoVault = { ...cakePool, isAutoVault: true }
    // return [cakeAutoVault, ...poolsWithoutAutoVault]
    */
    return [...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

  usePollFarmsData()
  // useFetchCakeVault()
  useFetchPublicDomainsData()

  useEffect(() => {
    const showMorePools = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
          if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          return poolsCurrentlyVisible
        })
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [observerIsSet])

  const showFinishedPools = location.pathname.includes('history')

  const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }
  /*
  const sortPools = (poolsToSort: Pool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: Pool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getCakeVaultEarnings(
                account,
                cakeAtLastUserAction,
                userShares,
                pricePerFullShare,
                pool.earningTokenPrice,
              ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.isAutoVault ? totalCakeInVault.toNumber() : pool.totalStaked.toNumber()),
          'desc',
        )
      default:
        return poolsToSort
    }
  }

  chosenPools = sortPools(chosenPools).slice(0, numberOfPoolsVisible)
  chosenPoolsLength.current = chosenPools.length
*/
  const chosenPools = pools
  /*
  const cardLayout = (
    <CardLayout>
      {chosenPools.map((pool) =>
        (
          <PoolCard key={pool.tokenId} pool={pool} account={account} />
        ),
      )}
    </CardLayout>
  )
*/
  const tableLayout = <PoolsTable pools={chosenPools} account={account} userDataLoaded={userDataLoaded} />

  return (
    <>
      <Page>
        <PageHeader>
          <Flex
            justifyContent="space-between"
            flexDirection={['column', null, null, 'row']}
            style={{ marginBottom: '0px' }}
          >
            <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
              <Center>
                <Heading as="h1" scale="xl" color="text" mb="10px">
                  {t('My Non-Fungible Domain Name')}
                </Heading>
              </Center>
            </Flex>
            {/*
          <Flex flex="1" height="fit-content" justifyContent="center" alignItems="center" mt={['24px', null, '0']}>
            <HelpButton />
            <BountyCard />
          </Flex>
          */}
          </Flex>
        </PageHeader>
        <PoolControls>
          {/*
          <PoolTabButtons
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            hasStakeInFinishedPools={hasStakeInFinishedPools}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
          */}
          {/*
          <FilterContainer>
            <LabelWrapper>
              <Text fontSize="16px" color="#3e3e3e" textTransform="capitalize" marginRight="10px">
                {t('Sort by')}
              </Text>
              <ControlStretch>
                <Select
                  options={[
                    {
                      label: t('Hot'),
                      value: 'hot',
                    },
                    {
                      label: t('APR'),
                      value: 'apr',
                    },
                    {
                      label: t('Earned'),
                      value: 'earned',
                    },
                    {
                      label: t('Total staked'),
                      value: 'totalStaked',
                    },
                  ]}
                  onChange={handleSortOptionChange}
                />
              </ControlStretch>
            </LabelWrapper>
            <LabelWrapper style={{ marginLeft: 16 }}>
              <Text fontSize="16px" color="#3e3e3e" textTransform="capitalize" marginRight="10px">
                {t('Search')}
              </Text>
              <SearchInput onChange={handleChangeSearchQuery} placeholder="Search Domains" />
            </LabelWrapper>
          </FilterContainer>
          */}
        </PoolControls>

        {/*
        <ComingSoonWrapper>
          <Heading as="h2" scale="lg" color="text" mb="15px" bold style={{ color: '#FE5A51' }}>{t('Coming soon. Follow us:')}</Heading>
          <SocialLinksWrapper>
            <SocialLinks />
          </SocialLinksWrapper>
        </ComingSoonWrapper>
        */}
        {showFinishedPools && (
          <Text fontSize="20px" color="failure" pb="32px">
            {t('These pools are no longer distributing rewards. Please unstake your tokens.')}
          </Text>
        )}
        {/*
        {account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center" mb="4px">
            <Loading />
          </Flex>
        )}
        */}
        {/*
        {viewMode === ViewMode.CARD ? cardLayout : tableLayout}
        */}

        {!account && (
          <>
            <Center>
              <ConnectWalletButton mt="24px" mb="20px" />
            </Center>
            <Center>
              <Text mb="20px">{t('Connect to your wallet to see all your domains')}</Text>
            </Center>
          </>
        )}

        {account && tableLayout}

        <div ref={loadMoreRef} />
        {/*
        <Image
          mx="auto"
          mt="12px"
          src="/images/avatar.png"
          alt="Binosour illustration"
          width={192}
          height={192}
        />
        */}
      </Page>
    </>
  )
}

export default Domains
