import { useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
// import BigNumber from 'bignumber.js'
import { Token, TokenAmount } from '@pancakeswap/sdk'
import { useXTDomainNFTContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched

function useGetNFTPrice(): BigNumber | undefined {
  const contract = useXTDomainNFTContract()

  const price: BigNumber = useSingleCallResult(contract, 'getMintPrice')?.result?.[0]

  return price;
}

export default useGetNFTPrice
