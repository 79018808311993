import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

interface BinosaurLogoProps extends SvgProps {
  isDark: boolean;
}

const BinosaurLogo: React.FC<BinosaurLogoProps> = ({ isDark, ...props }) => {
  const textColor = isDark ? "#FFFFFF" : "#000000";
  return (
    <Svg viewBox="0 0 316 68" {...props}>

      <g transform="translate(0.000000,68.000000) scale(0.100000,-0.100000)"
        fill={textColor} stroke="none">
        <path d="M663 602 c-12 -8 -18 -31 -15 -64 2 -22 47 -34 67 -18 20 17 20 76
-1 84 -21 8 -36 7 -51 -2z"/>
        <path d="M253 591 c-39 -17 -43 -41 -43 -265 0 -268 -6 -256 138 -256 118 0
163 12 195 49 50 60 40 167 -18 205 l-31 20 30 26 c57 52 44 170 -23 205 -35
18 -216 29 -248 16z m198 -82 c32 -16 29 -100 -4 -121 -7 -4 -45 -8 -84 -8
l-73 0 0 65 c0 50 4 66 18 74 18 12 115 5 143 -10z m-19 -209 c42 -12 58 -34
58 -78 0 -52 -27 -73 -103 -79 -88 -7 -97 0 -97 87 0 36 3 70 7 73 10 11 93 8
135 -3z"/>
        <path d="M898 436 c-80 -22 -78 -18 -78 -202 l0 -164 40 0 40 0 0 143 0 144
25 11 c31 14 94 15 115 2 12 -7 15 -40 18 -155 l4 -146 37 3 36 3 3 115 c4
134 -4 210 -23 236 -11 15 -29 19 -92 21 -43 2 -99 -3 -125 -11z"/>
        <path d="M1281 433 c-49 -25 -71 -79 -70 -178 0 -145 30 -185 146 -192 77 -5
122 12 157 59 24 34 35 185 17 240 -21 63 -52 83 -139 86 -57 2 -86 -2 -111
-15z m159 -80 c19 -25 21 -38 18 -106 -3 -68 -6 -82 -27 -98 -34 -28 -86 -25
-117 7 -24 24 -26 30 -22 106 3 69 6 82 27 99 36 29 95 25 121 -8z"/>
        <path d="M1654 441 c-57 -25 -80 -101 -49 -161 14 -27 41 -41 129 -67 38 -12
55 -37 40 -60 -14 -22 -78 -28 -131 -14 -32 9 -40 8 -50 -6 -35 -46 7 -73 115
-73 100 0 152 40 152 116 0 58 -22 80 -110 112 -78 29 -94 48 -69 79 11 12 27
14 85 10 72 -5 72 -5 77 21 3 15 1 31 -4 37 -13 13 -158 17 -185 6z"/>
        <path d="M1960 441 c-19 -5 -26 -14 -28 -39 l-3 -33 74 7 c92 8 117 -3 117
-50 l0 -34 -86 -3 c-79 -4 -87 -6 -110 -32 -17 -20 -24 -40 -24 -72 0 -65 15
-94 57 -112 44 -18 164 -13 210 9 l28 13 3 140 c3 152 -4 186 -44 204 -27 12
-153 13 -194 2z m158 -258 l-3 -48 -63 -3 c-59 -3 -63 -2 -72 23 -13 35 -13
41 6 59 10 11 34 16 75 16 l60 0 -3 -47z"/>
        <path d="M2756 436 c-21 -8 -44 -20 -50 -26 -12 -12 -22 -274 -12 -316 6 -22
11 -25 44 -22 l37 3 3 140 c1 77 7 144 13 150 5 5 33 12 62 15 50 5 52 6 52
35 0 29 -1 30 -55 32 -30 2 -72 -4 -94 -11z"/>
        <path d="M650 255 l0 -185 40 0 40 0 0 185 0 185 -40 0 -40 0 0 -185z" />
        <path d="M2290 281 c0 -155 1 -161 24 -188 24 -27 27 -28 128 -28 83 0 109 4
131 18 l27 17 0 171 0 170 -37 -3 -38 -3 -3 -140 c-3 -159 -6 -165 -78 -165
-69 0 -74 10 -74 171 l0 139 -40 0 -40 0 0 -159z"/>
      </g>
    </Svg>
  );
};

export default React.memo(BinosaurLogo, (prev, next) => prev.isDark === next.isDark);
