import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="51.000000pt" height="56.000000pt" viewBox="0 0 51.000000 56.000000"
      preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(0.000000,56.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M11 546 c-8 -9 -11 -83 -9 -247 3 -232 3 -234 25 -244 13 -5 23 -18
23 -27 0 -14 8 -18 40 -18 33 0 40 3 40 20 0 19 7 20 125 20 118 0 125 -1 125
-20 0 -16 7 -20 36 -20 26 0 37 5 41 18 2 10 14 22 26 27 22 10 22 14 25 244
2 164 -1 238 -9 247 -17 20 -471 20 -488 0z m474 -241 l0 -230 -232 -3 -233
-2 0 228 c0 126 3 232 7 236 4 3 108 5 232 4 l226 -3 0 -230z m-375 -265 c0
-5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m330
0 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4 25
-10z"/>
        <path d="M60 300 l0 -200 195 0 195 0 0 34 c0 19 4 38 10 41 13 8 13 65 0 65
-13 0 -13 117 0 125 6 3 10 19 10 35 0 16 -4 32 -10 35 -6 3 -10 19 -10 36 l0
29 -195 0 -195 0 0 -200z m370 160 c0 -23 -4 -30 -20 -30 -16 0 -20 -7 -20
-30 0 -23 4 -30 20 -30 18 0 20 -7 20 -65 0 -58 -2 -65 -20 -65 -16 0 -20 -7
-20 -35 0 -28 4 -35 20 -35 15 0 20 -7 20 -25 l0 -25 -177 2 -178 3 -3 170
c-1 93 0 175 2 182 4 10 47 13 181 13 l175 0 0 -30z m20 -60 c0 -13 -7 -20
-20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m0 -195 c0 -8
-9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z"/>
        <path d="M125 355 c-14 -13 -25 -36 -25 -50 0 -36 42 -75 80 -75 41 0 70 31
70 75 0 69 -76 100 -125 50z m79 -1 c22 -8 20 -21 -4 -29 -21 -7 -50 9 -50 26
0 10 31 12 54 3z m-42 -84 l-9 -24 -18 27 c-9 15 -14 34 -11 44 6 15 9 15 27
-3 15 -16 18 -26 11 -44z m68 20 c0 -16 -26 -40 -44 -40 -14 0 -4 40 12 49 25
15 32 13 32 -9z"/>
      </g>
    </Svg>
  );
};

export default Icon;
