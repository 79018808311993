import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { updateUserStakedBalance, updateUserBalance } from 'state/actions'
import { stakeFarm } from 'utils/calls'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL, DEFAULT_GAS_LIMIT } from 'config'
import { BIG_TEN } from 'utils/bigNumber'
import { useMasterchef, useSousChef, useXTDomainNFTContract } from 'hooks/useContract'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const sousStake = async (sousChefContract, amount, decimals = 18) => {
  const tx = await sousChefContract.deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString(), options)
  const receipt = await tx.wait()
  return receipt.status
}

const sousStakeBnb = async (sousChefContract, amount) => {
  const tx = await sousChefContract.deposit(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(), options)
  const receipt = await tx.wait()
  return receipt.status
}

const settingDomain = async (xtDomainNFTContract, tokenId, nftName, tokenURI) => {
  const tx = await xtDomainNFTContract.setNFTURI(nftName, tokenURI)
  const receipt = await tx.wait()
  return receipt.status
}

const useSettingDomain = (sousId: number, isUsingBnb = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()
  const sousChefContract = useSousChef(sousId)

  const xtDomainNFTContract = useXTDomainNFTContract()

  const handleSetting = useCallback(
    async (tokenId: number, nftName: string, tokenURI: string) => {
      await settingDomain(xtDomainNFTContract, tokenId, nftName, tokenURI)
      // dispatch(updateUserStakedBalance(sousId, account))
      // dispatch(updateUserBalance(sousId, account))
    },
    // [account, dispatch, xtDomainNFTContract],
    [xtDomainNFTContract],
  )

  return { onSetting: handleSetting }
}

export default useSettingDomain
