import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="67.000000pt" height="49.000000pt" viewBox="0 0 67.000000 49.000000"
      preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(0.000000,49.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M195 483 c-36 -9 -54 -35 -66 -94 l-12 -59 -38 0 c-22 0 -39 -4 -39
-10 0 -5 16 -10 35 -10 28 0 35 -4 35 -19 0 -11 -9 -22 -19 -26 -11 -3 -34
-22 -50 -41 -58 -66 -36 -165 46 -205 58 -28 106 -18 155 32 l37 39 85 0 c84
0 86 -1 100 -29 44 -83 170 -71 197 20 13 43 2 84 -32 115 -11 10 -19 30 -19
50 0 45 -19 60 -89 68 l-61 7 0 44 c0 33 4 45 15 45 8 0 15 5 15 10 0 6 -11
10 -25 10 -24 0 -25 -3 -25 -55 0 -51 -2 -55 -24 -55 -28 0 -32 8 -41 82 -6
46 -4 56 10 61 9 4 13 11 10 17 -6 10 -161 12 -200 3z m5 -91 l0 -69 -30 5
c-16 2 -30 7 -30 11 1 22 23 97 32 108 21 26 28 12 28 -55z m145 46 c13 -63
25 -146 25 -180 0 -35 -2 -38 -28 -38 -21 0 -32 7 -40 25 -6 13 -27 35 -47 47
-34 23 -35 24 -35 96 l0 72 60 0 c54 0 60 -2 65 -22z m-128 -147 c53 -25 93
-85 93 -141 0 -29 -4 -40 -15 -40 -9 0 -15 9 -15 25 0 66 -50 125 -117 140
-37 8 -45 35 -10 35 12 0 41 -9 64 -19z m360 -13 c7 -6 13 -21 13 -33 0 -19
-7 -23 -41 -28 -48 -6 -74 -28 -91 -73 -10 -28 -17 -34 -40 -34 l-28 0 0 96 0
97 87 -7 c48 -4 93 -12 100 -18z m-364 -48 c82 -64 38 -200 -64 -200 -75 0
-119 43 -119 115 0 29 8 45 34 71 29 29 41 34 79 34 29 0 53 -7 70 -20z m157
-50 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20
-20z m243 0 c33 -26 43 -70 23 -108 -17 -34 -34 -42 -82 -42 -62 0 -99 62 -70
118 28 55 83 68 129 32z m-243 -55 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0
8 9 15 20 15 11 0 20 -7 20 -15z"/>
        <path d="M424 256 c-4 -10 -2 -22 4 -28 8 -8 13 -7 18 6 4 10 2 22 -4 28 -8 8
-13 7 -18 -6z"/>
        <path d="M480 250 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0
-10 -9 -10 -20z"/>
        <path d="M103 208 c-33 -16 -48 -61 -33 -98 23 -55 84 -67 125 -25 65 64 -8
163 -92 123z m77 -28 c38 -38 12 -100 -42 -100 -31 0 -61 38 -53 68 12 52 60
67 95 32z"/>
        <path d="M522 148 c-22 -22 -14 -67 13 -80 37 -17 75 3 75 40 0 35 -16 52 -50
52 -14 0 -31 -5 -38 -12z m63 -38 c0 -18 -6 -26 -23 -28 -13 -2 -25 3 -28 12
-10 26 4 48 28 44 17 -2 23 -10 23 -28z"/>
      </g>
    </Svg>
  );
};

export default Icon;
