import React from 'react'
import styled from 'styled-components'
import { ListViewIcon, CardViewIcon, IconButton } from '@pancakeswap/uikit'

export enum ViewMode {
  'TABLE' = 'TABLE',
  'CARD' = 'CARD',
}

interface ToggleViewProps {
  viewMode: ViewMode
  onToggle: (mode: ViewMode) => void
}

const Container = styled.div`
  margin-right: 0px;
  margin-left: -8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 0;
    margin-right: 16px;
  }
`

const ToggleView: React.FunctionComponent<ToggleViewProps> = ({ viewMode, onToggle }) => {
  const handleToggle = (mode: ViewMode) => {
    if (viewMode !== mode) {
      onToggle(mode)
    }
  }

  return (
    // <Container>
    //   <IconButton variant="text" scale="sm" id="clickPoolCardView" onClick={() => handleToggle(ViewMode.CARD)}>
    //     <CardViewIcon color={viewMode === ViewMode.CARD ? 'primary' : 'textDisabled'} />
    //   </IconButton>
    //   <IconButton variant="text" scale="sm" id="clickPoolTableView" onClick={() => handleToggle(ViewMode.TABLE)}>
    //     <ListViewIcon color={viewMode === ViewMode.TABLE ? 'primary' : 'textDisabled'} />
    //   </IconButton>
    // </Container>

    <Container>
      <IconButton variant="text" scale="sm" id="clickFarmCardView" onClick={() => handleToggle(ViewMode.CARD)}>
        <CardViewIcon width="25px" color={viewMode === ViewMode.CARD ? '#FE5A51' : '#BDC2C4'} />
      </IconButton>
      <IconButton variant="text" scale="sm" id="clickFarmTableView" onClick={() => handleToggle(ViewMode.TABLE)}>
        <ListViewIcon width="25px" color={viewMode === ViewMode.TABLE ? '#FE5A51' : '#BDC2C4'} />
      </IconButton>
    </Container>
  )
}

export default ToggleView
