import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
// import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'
import { PancakeTheme } from 'components/Menu/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    // font-family: 'Gilroy';
    // src: local('Gilroy'), url(/fonts/gilroy/Gilroy-Regular.ttf) format('truetype');
    // font-family: 'Carnas';
    // src: local('Carnas'), url(/fonts/carnas/Carnas-Light.ttf) format('truetype');
  }
  * {
    // font-family: 'Gilroy', sans-serif;
    // font-family: 'Carnas', sans-serif;
    font-family: 'Open Sans', sans-serif;
    
  }
  body {
    background-color: '#fd9273' ;

    img {
      height: auto;
      max-width: 100%;
    }
  }
  `

export default GlobalStyle
